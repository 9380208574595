import Vue from 'vue';
import TYPES from '@/types';
import { emailFormat, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import CreateAnonymousTicketCommand
  from '@/modules/zendesk/anonymous-ticket/application/commands/create-anonymous-ticket-command';

// Domain
import { AnonymousTicketDto } from '@/modules/zendesk/anonymous-ticket/domain/dtos/anonymous-ticket-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SupportWidgetAnonymousCommentViewModel {
  @Inject(TYPES.CREATE_ANONYMOUS_TICKET_COMMAND)
  private readonly create_anonymous_ticket_command!: CreateAnonymousTicketCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  i18n_namespace = 'components.support-widget.support_widget_anonymous_comment';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  private readonly subject = this.translate('subject');

  is_loading = false;

  is_valid_form = false;

  inputs = {
    name: '',
    email: '',
    comment: '',
  };

  rules = {
    name: [requiredRule],
    email: [requiredRule, emailFormat],
    comment: [requiredRule],
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  createTicketSupportZendesk= async () => {
    try {
      this.is_loading = true;

      const anonymous_ticket_dto: AnonymousTicketDto = {
        name: this.inputs.name,
        email: this.inputs.email,
        subject: this.subject,
        comment: this.inputs.comment,
      };
      await this.create_anonymous_ticket_command.execute(anonymous_ticket_dto);
      this.view.$emit('changeEmail', this.inputs.email);
      this.view.$emit('successStep');
    } catch {
      this.view.$emit('errorStep');
    } finally {
      this.inputs.name = '';
      this.inputs.email = '';
      this.inputs.comment = '';
      this.is_loading = false;
    }
  }
}
