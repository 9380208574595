







































































import { Component, Vue } from 'vue-property-decorator';

import SupportWidgetAnonymousCommentViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-anonymous-comment-view-model';

@Component({ name: 'SupportWidgetAnonymousComment' })
export default class SupportWidgetAnonymousComment extends Vue {
  support_anonymous_comment_view_model = Vue.observable(
    new SupportWidgetAnonymousCommentViewModel(this),
  );
}
